import request from "@/utils/Request";

export function info() {
  return request.get("/client-order/order-source-info", {
    orderSource: process.env.VUE_APP_ORDER_SOURCE,
  });
}

export function current() {
  return request.get("/client-order");
}

export function create(clientName, clientAuth, clientComment1) {
  return request.post("/client-order/", {
    clientName,
    clientAuth,
    clientComment1,
    orderSource: process.env.VUE_APP_ORDER_SOURCE,
  });
}

export function comment(comment, orderStatus) {
  return request.post("/client-order/client-comment", {
    comment: comment,
    prevStatus: orderStatus,
    isClientResponseWaiting: 1,
  });
}

export function reset() {
  return request.post("/client-order/reset-order");
}
