import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueI18n from 'vue-i18n'

import "./mixins";
import "./hack";
import "./plugins";

Vue.use(VueI18n);
Vue.use(ElementUI);
Vue.config.productionTip = false;

// import Langs from "./langs/index"


async function init() {
  new Vue({
    // i18n: await Langs.asyncLoad(process.env.VUE_APP_LANG),
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");

}

init().catch(() => {
  console.error("init error");
});
