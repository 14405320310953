import Vue from "vue";
import loading from "./loading.js"

Vue.mixin({
  data() {
    return {
      VUE_APP_TIMEOUT_TIME: process.env.VUE_APP_TIMEOUT_TIME || 2000
    }
  }
});

Vue.mixin(loading);
