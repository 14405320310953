import axios from "axios";

let instance = axios.create({
  baseURL: "/api",
  timeout: 30 * 1000,
});
instance.interceptors.response.use((res) => {
  let data = res.data;
  if (data.success) {
    return data.data;
  } else {
    return Promise.reject("error");
  }
});
export default instance;
