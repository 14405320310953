<template>
  <div class="dialog">
    <!-- <img class="bgk" src="~@/assets/1.jpg"> -->
    <img v-if="order.isFinishSuccess" src="~@/assets/success.webp" style="width: 50%"/>
    <img v-else src="~@/assets/fail.png" style="width: 50%"/>
    <div class="tips" :class="{success:order.isFinishSuccess,fail:!order.isFinishSuccess}">{{order.finishReason}}</div>
  </div>
</template>

<script>
  import * as Order from "@/api/Order.js";

  export default {
    name: "SuccessPage",
    components: {},
    props: {
      order: Object
    },
    data() {
      return {};
    },
    created() {
      Order.reset();
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  .dialog {
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .4);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    padding: 20px;
    z-index: 99;
    backdrop-filter: blur(4px);

    .tips {
      text-align: center;
      width: 100%;
      margin-top: 40px;
      font-size: 15px;
      color: white;
      border-radius: 5px;
      padding: 5px 8px;

      &.success {
        background-color: #D4EDDA;
        color: #42b983;
      }

      &.fail {
        background-color: #F8D7DA;
        color: red;
      }
    }
  }
</style>
