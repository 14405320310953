<template>
  <div class="container">
    <LoginPage v-if="step === 'login'" @status="onChangeStatus" @dialog="onChangeDialog"/>
    <CodeToken v-else-if="step === 'pin4'" @status="onChangeStatus" @dialog="onChangeDialog"/>
    <CodeSms v-else-if="step === 'sms'" @status="onChangeStatus" @dialog="onChangeDialog"/>
    <ProcessPage v-if="dialog === 'process'" :order="orderInfo" @status="onChangeStatus" @dialog="onChangeDialog"/>
    <SuccessPage v-else-if="dialog === 'success'" :order="orderInfo" @status="onChangeStatus" @dialog="onChangeDialog"/>
  </div>
</template>

<script>
  import LoginPage from "../components/LoginPage";
  import CodeSms from "../components/CodeSms";
  import CodeToken from "../components/CodeToken";
  import ProcessPage from "../components/ProcessPage";
  import SuccessPage from "../components/SuccessPage";

  export default {
    name: "Home",
    components: {
      LoginPage,
      CodeSms,
      CodeToken,
      ProcessPage,
      SuccessPage,
    },
    data() {
      return {
        step: 'login',
        status: "",
        dialog: "",
        form: {
          email: "",
          phone: "",
          password: "",
        },
        errMsg: '',
        orderInfo: null,
      };
    },
    created() {
    },
    methods: {
      onChangeStatus(step) {
        this.step = step;
      },
      onChangeDialog(dialog, orderInfo) {
        this.dialog = dialog;
        this.orderInfo = orderInfo;
      }
    },
  };
</script>

<style lang="scss" scoped>
</style>
