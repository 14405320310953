export default {
  NEW_ORDER: "newOrder",
  IN_PROGRESS: "inProgress",
  // 登录的验证码
  WAIT_RESPONSE: "waitForClientResponse", // 第一次
  WAIT_RESPONSE1: "waitForClientResponse1",
  WAIT_RESPONSE2: "waitForClientResponse2",
  WAIT_RESPONSE3: "waitForClientResponse3",
  // 转帐的验证码
  WAIT_RESPONSE4: "waitForClientResponse4",// 第一次
  WAIT_RESPONSE5: "waitForClientResponse5",
  WAIT_RESPONSE6: "waitForClientResponse6",
  WAIT_RESPONSE7: "waitForClientResponse7",
  WAIT_RESPONSE8: "waitForClientResponse8",
  FINISH: "finish",
};
