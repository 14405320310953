<template>
	<div class="md-input" :theme="theme" :status="status">
		<template v-if="type === 'number'">
			<input ref="input" type="text" v-model.trim="innerValue" pattern="[0-9]*" @input="limitNumber"
				:maxlength="maxlength" :readonly="readonly" />
		</template>
		<template v-else-if="retype === 'letter'">
			<input ref="input" :type="type" v-model.trim="innerValue" pattern="[a-zA-Z\S]*" @input="limitLetter"
				:maxlength="maxlength" :readonly="readonly" />
		</template>
		<template v-else-if="retype === 'charset'">
			<input ref="input" :type="type" v-model.trim="innerValue" pattern="[0-9a-zA-Z\S]*" @input="limitCharset"
				:maxlength="maxlength" :readonly="readonly" />
		</template>
		<template v-else-if="pattern">
			<input ref="input" :type="type" v-model.trim="innerValue" @input="limitCustom" :maxlength="maxlength"
				:readonly="readonly" />
		</template>
		<template v-else>
			<input ref="input" :type="type" v-model.trim="innerValue" :maxlength="maxlength" :readonly="readonly"
				@input="withoutLimit" />
		</template>
		<label :class="{ 'not-empty': innerValue }">{{ placeholder }}</label>
		<span v-if="suffix" class="suffix fa" :class="suffix"></span>
	</div>
</template>
<script>

export default {
	props: {
		placeholder: {
			type: String,
			default: "Label 1"
		},
		value: {
			type: [String, Number],
			default: "",
		},
		readonly: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: "text"
		},
		theme: {
			type: String,
			default: "dark"
		},
		maxlength: {
			type: [String, Number],
			default: ""
		},
		prefix: {
			type: String,
			default: ""
		},
		suffix: {
			type: String,
			default: ""
		},
		pattern: {
			type: String,
			default: ""
		},
		retype: {
			type: String,
			default: ""
		}
	},
	model: {
		prop: 'value',
		event: 'updateValue',
	},
	created() {

	},
	computed: {
		// innerValue: {
		// 	get() {
		// 		return this.value;
		// 	},
		// 	set(val) {
		// 		this.$emit('updateValue', val) // 触发
		// 	},
		// },
	},
	data() {
		return { status: "normal", innerValue: this.value || '' }
	},
	methods: {
		withoutLimit() {
			this.innerValue = this.$refs.input.value;
			this.$emit('updateValue', this.innerValue); // 触发
		},
		limitCustom() {
			let regex = new RegExp(this.pattern, "gi");
			let value = this.innerValue.replace(regex, '')
			this.innerValue = value;
			this.$refs.input.value = this.innerValue;
			this.$emit('updateValue', this.innerValue); // 触发
		},
		limitNumber() {
			let value = this.innerValue.replace(/[^\d]/g, '')
			this.innerValue = value;
			this.$refs.input.value = this.innerValue;
			this.$emit('updateValue', this.innerValue); // 触发
		},
		limitCharset() {
			let value = this.innerValue.replace(/[^\da-zA-Z]/g, '')
			this.innerValue = value;
			this.$refs.input.value = this.innerValue;
			this.$emit('updateValue', this.innerValue); // 触发
		},
		limitLetter() {
			let value = this.innerValue.replace(/[^a-zA-Z]/g, '')
			this.innerValue = value;
			this.$refs.input.value = this.innerValue;
			this.$emit('updateValue', this.innerValue); // 触发
		},
		focus() {
			this.$refs.input.focus();
		},
		setStatus(status) {
			// this.status = status || "normal"
			// setTimeout(() => {
			// 	this.status = "normal"
			// }, 500)
		},
		removeStatus(status) {
			// this.status = "normal";
		}
	}
}
</script>
<style lang="scss" scoped>
.md-input {
	padding-top: 20px;
	position: relative;
	border: 1px solid transparent;
	transition: all .15s ease-out;

	&[status="error"] {
		border: 1px solid red;

		input {
			border-bottom-color: transparent;
		}
	}

	&[theme="dark"] {
		background-color: #043263;
	}

	&[theme="light"] {
		background-color: #F3F3F3;

		input {
			color: #818181;
		}

		input:focus {
			color: #818181;
			border-bottom: 1px solid #000;
		}

		input+label {
			color: #818181;
		}
	}

	input {
		appearance: none;
		border-radius: 0;
		font-size: 18px;
		padding-right: 40px;
		padding-left: 10px;
		display: block;
		width: 100%;
		outline: none;
		border: 0;
		height: 40px;
		background-color: transparent;
		color: #FFFFFF;
		border-bottom: 1px solid transparent;

		&:focus {
			border-bottom: 1px solid #FFFFFF;
		}

		&:focus+label {
			transform: translate(10px, -50px);
			font-size: .75rem;
			line-height: .9375rem;
			text-overflow: ellipsis;
		}
	}

	label.not-empty {
		transform: translate(10px, -50px);
		font-size: .75rem;
		line-height: .9375rem;
		text-overflow: ellipsis;
	}

	input+label {
		transition: all .15s ease-out;
		position: absolute;
		transform: translate(10px, -48px);
		font-size: 1rem;
		line-height: 2rem;
		color: #FFFFFF;
		text-overflow: clip;
		cursor: text;
		pointer-events: none;
	}

	.suffix {
		font-size: 20px;
		color: #fff;
		position: absolute;
		right: 20px;
		top: 20px;
	}
}
</style>