import Vue from "vue";
import {Loading} from "element-ui";

let loadingInstance;

Vue.prototype.showLoading = function () {
  if (!loadingInstance) {
    loadingInstance = Loading.service({});
  }
};

Vue.prototype.hideLoading = function () {
  if (loadingInstance) {
    loadingInstance.close();
    loadingInstance = undefined;
  }
};
